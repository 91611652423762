import {actionTypes, ROUTES} from "../constants/actionTypes";
import reCache from "../../../utils/reCache";
import uniFetch from "../../../utils/uniFetch";
import loadingTypes from "../constants/loadingTypes";


export const setRoute = nextValue => dispatch => {
  dispatch({type: actionTypes.SET_ROUTE, value: nextValue});
};

export const getPersonDetail = personId => (dispatch, getState) => {
  const {siteUrl} = getState();
  const urlGetPerson = `${siteUrl}/edw/api/entities/${personId}/`;

  uniFetch(urlGetPerson, "GET", "", true)
    .then(data => {
      let isOperator = false;
      const presetPattern = 'operator-customer';
      for (const sm of data.marks) {
        for (const cl of sm.view_class) {
          if (cl.startsWith(presetPattern))
            isOperator = true;
        }
      }
      dispatch({type: actionTypes.GET_PERSON_DETAIL, data, isOperator});
      dispatch({type: actionTypes.ADD_LOADING, loading: loadingTypes.person});

      if (data.entity_model === 'privateperson')
        dispatch(getConfig())
    })
    .catch(error => {
      error = JSON.parse(error);
      dispatch({type: actionTypes.ERROR_GET_PERSON_DETAIL, error})
    })
};

export const getConfig = () => (dispatch, getState) => {
  const {siteUrl} = getState();
  const urlGetConfig = `${siteUrl}/api/config/`;

  uniFetch(urlGetConfig)
    .then(data => {
      const config = {
        LAT_DEFAULT: +data.LAT_DEFAULT,
        LNG_DEFAULT: +data.LNG_DEFAULT,
        YANDEX_MAPS_API_KEY: data.YANDEX_MAPS_API_KEY,
        REGION_NAME_GENITIVE: data.REGION_NAME_GENITIVE,
        PORTAL_NAME: data.PORTAL_NAME
      };
      dispatch({type: actionTypes.GET_CONFIG, config});
      dispatch({type: actionTypes.ADD_LOADING, loading: loadingTypes.config});
      dispatch(getProblemDetail());
    })
    .catch(error => {
      error = JSON.parse(error);
      dispatch({type: actionTypes.ERROR_GET_CONFIG, error})
    })
};

export const getProblemDetail = () => (dispatch, getState) => {
  const {siteUrl, personDetail} = getState();
  const urlGetDraftProblem = `${siteUrl}/edw/api/entities/${personDetail.problemId}/`;

  uniFetch(urlGetDraftProblem, "GET", "", true)
    .then(data => {
      dispatch({type: actionTypes.GET_PROBLEM_DETAIL, problem: data});
      dispatch({type: actionTypes.ADD_LOADING, loading: loadingTypes.problem});
    })
    .catch(error => {
      error = JSON.parse(error);
      dispatch({type: actionTypes.ERROR_GET_PROBLEM_DETAIL, error})
    })
};

export const setData = data => (dispatch, getState) => {
  const {problem, siteUrl} = getState();

  const url = `${siteUrl}/edw/api/entities/${problem.id}/`;
  uniFetch(url, "PATCH", data, true)
    .then(response => {
      delete response.description;
      dispatch({type: actionTypes.SET_DATA, data: response});

      if (data.hasOwnProperty("description"))
        dispatch({type: actionTypes.ADD_LOADING, loading: loadingTypes.setDescription});

      if (data.hasOwnProperty("typical_problem_id")) {
        dispatch(setRoute(ROUTES.STEP_THREE));
        dispatch(removeLoading("problem"));
      }

      if (data.hasOwnProperty("geoposition"))
        dispatch({type: actionTypes.ADD_LOADING, loading: loadingTypes.setGeoposition})
    })
    .catch(error => {
      error = JSON.parse(error);
      dispatch({type: actionTypes.ERROR_SET_DATA, error})
    })
};

export const close = () => (dispatch, getState) => {
  const {router} = getState(),
    container = document.querySelector(".smart-assist");

  parent.postMessage("closeAuth", "*");

  container.classList.add("d-none");
  if (router === ROUTES.FINISH_SCREEN) {
    dispatch({type: actionTypes.SET_ROUTE, value: ROUTES.STEP_ONE});
    dispatch({type: actionTypes.REMOVE_LOADING, clearAll: true});
    dispatch({type: actionTypes.SET_TYPICAL_PROBLEM_ID, typicalProblemId: null})
  }
};

export const getPlace = () => (dispatch, getState) => {
  const {siteUrl, problem} = getState(),
    {place} = problem;

  const urlGetConfig = `${siteUrl}/api/config/`;
  uniFetch(urlGetConfig)
    .then(config => {
      const geoposition = place?.extra?.geoposition || `${config.LAT_DEFAULT},${config.LNG_DEFAULT}`;
      const urlSearchMoreLikeThis = `${siteUrl}/search/more_like_this/?m=nash_region.place&g=${geoposition}`;
      return uniFetch(urlSearchMoreLikeThis, "GET", "", true)
    })
    .then(data => {
      dispatch({type: actionTypes.GET_PLACE, place: data});
      dispatch({type: actionTypes.REMOVE_LOADING, loading: loadingTypes.setGeoposition});
      dispatch({type: actionTypes.ADD_LOADING, loading: loadingTypes.place});
    })
    .catch(error => {
      error = JSON.parse(error);
      dispatch({type: actionTypes.ERROR_GET_PLACE, error})
    })
};

export const getThemes = description => (dispatch, getState) => {
  const {siteUrl, place, personDetail} = getState();

  let themes = [];

  let like = "",
    unlike = "";

  // TODO: Переделать проверку через confidence
  place.results && place.results.map(item => {
    if (item.score > 0)
      like += ` ${item.title}`;
    else
      unlike += ` ${item.title}`;
  });

  const regex = /<.*?>|&nbsp;/gi;
  description = description ? description.replace(regex, ' ') : null;

  const urlGetConfig = `${siteUrl}/api/config/`;
  uniFetch(urlGetConfig)
    .then(config => {
      let hashTag = "";

      if (description) {
        hashTag = JSON.parse(config.HASHTAG_FOR_SEARCH_MORE_LIKE_THIS);
        hashTag = personDetail.isOperator ? hashTag.operator : hashTag.private_person;
        if (hashTag)
          description += hashTag.toLowerCase().replace('#', ' _');
      }

      // Деление исключает превышение длины запроса при переводе в кодировку (1 русский символ = 6 в кодировке)
      const lenDescription = Math.floor((config.MAX_SEARCH_LENGTH - unlike.length * 6 - like.length * 6 - hashTag.length) / 6);
      description = description.slice(0, lenDescription);

      const urlSearch = reCache(`${siteUrl}/search/more_like_this/?m=nash_region.particularproblem&u=${unlike}&iu=${like}&q=${description}`);
      //todo: переделать на POST
      return uniFetch(urlSearch, "GET", "", true)
    })
    .then(data => {
      themes = data.results;
      dispatch({type: actionTypes.GET_THEMES, themes});
      dispatch({type: actionTypes.ADD_LOADING, loading: loadingTypes.getThemes});
    })
    .catch(error => {
      dispatch({type: actionTypes.ERROR_GET_THEMES, error});
      dispatch({type: actionTypes.ADD_LOADING, loading: loadingTypes.getThemes});
    });
};

export const submitProblem = () => (dispatch, getState) => {
  const {personDetail, siteUrl} = getState(),
    {problemId} = personDetail;

  let data = [{id: problemId, transition: "draft_to_detected"}];

  const url = `${siteUrl}/edw/api/data-marts/particularproblem-dm/entities/`;
  uniFetch(url, "PATCH", data, true)
    .then(() => {
      dispatch({type: actionTypes.SUBMIT_PROBLEM});
      dispatch(setRoute(ROUTES.FINISH_SCREEN));
    })
    .catch(error => {
      error = JSON.parse(error);
      dispatch({type: actionTypes.ERROR_SUBMIT_PROBLEM, error})
    })
};

export const setSiteUrl = (protocol, domain) => dispatch => {
  const url = `${protocol}://${domain}`;

  dispatch({type: actionTypes.SET_SITE_URL, siteUrl: url});
  dispatch({type: actionTypes.ADD_LOADING, loading: loadingTypes.siteUrl});
};

export const removeLoading = loading => dispatch => {
  dispatch({type: actionTypes.REMOVE_LOADING, loading});
};

export const addLoading = loading => dispatch => {
  dispatch({type: actionTypes.ADD_LOADING, loading});
};

// Функция используется для поиска места по координатам
export const getSearchAddress = (ymaps, coordinates = []) => dispatch => {
  ymaps.geocode(coordinates, {
    results: 1
  })
    .then(res => {
      const result = res.geoObjects.toArray();

      const searchAddress = {
        value: result[0].properties._data.text,
        coordinates: result[0].geometry.getCoordinates(),
        label: result[0].properties._data.text
      };

      dispatch({type: actionTypes.GET_ADDRESS, searchAddress});
    })
    .catch(error => {
      error = JSON.parse(error);
      dispatch({type: actionTypes.ERROR_SEARCH_ADDRESS, error})
    })
};

// Функция используется для поиска мест по тексту
export const geocodeAddress = (ymaps, searchAddress = "") => (dispatch, getState) => {
  const {siteUrl} = getState();
  const urlGetConfig = `${siteUrl}/api/config/`;

  uniFetch(urlGetConfig)
    .then(config => {
      const address = `${JSON.parse(config.REGION_SEARCH_HELPER_JSON).ru[1]}${searchAddress}`;

      if (searchAddress.length < 5)
        dispatch({type: actionTypes.SEARCH_ADDRESSES, searchAddresses: []});
      else {
        ymaps.geocode(address)
          .then(res => {
            const result = res.geoObjects.toArray();

            const searchAddresses = result.map(item => {
              return {
                value: item.properties._data.text,
                coordinates: item.geometry.getCoordinates(),
                label: item.properties._data.text
              }
            });

            dispatch({type: actionTypes.SEARCH_ADDRESSES, searchAddresses});
          })
          .catch(error => {
            error = JSON.parse(error);
            dispatch({type: actionTypes.ERROR_SEARCH_ADDRESS, error})
          })
      }
    })
};

export const setTypicalProblemId = typicalProblemId => dispatch => {
  dispatch({type: actionTypes.SET_TYPICAL_PROBLEM_ID, typicalProblemId})
};

export const getTypicalProblemDetail = () => (dispatch, getState) => {
  const {typicalProblemId, siteUrl} = getState();
  const url = `${siteUrl}/edw/api/entities/${typicalProblemId}/`;

  uniFetch(url, "GET", "", true)
    .then(data => {
      dispatch({
        type: actionTypes.GET_TYPICAL_PROBLEM_DETAIL,
        image_status: data.image_status,
        deadline: data.deadline,
        conditions: data.conditions || {}
      });
      dispatch({type: actionTypes.ADD_LOADING, loading: loadingTypes.typicalProblemDetail});
    })
    .catch(error => {
      error = JSON.parse(error);
      dispatch({type: actionTypes.ERROR_GET_TYPICAL_PROBLEM_DETAIL, error})
    })
};

export const changeAmountImage = image => dispatch => {
  dispatch({type: actionTypes.CHANGE_AMOUNT_IMAGE, image})
};
